﻿$amount: 5%;
$alpha: 0.2;

$grey-lightest: #f2f2f2;
$grey-lighter: #dddddd;
$grey-light: #f4f6f9;
$grey-blue-dark: #e3e3e3;
$grey-blue-light: #eaedf3;
$grey-blue-lighter: #ececec;
$grey-blue: #c5d0de;
$grey: #333333;
$grey-dark: #333333;
$grey-darker: #333333;

$white: #fff;
$black: #000;

$yellow: #ffcb00;
$red: #ec0404;
$blue: #62b8ff;
$blue-light: #00A3E0;
$blue-lighter: #ADD8E6;
$green: #53b827;
$orange: #ff6800;
$purple: #9013fe;

$transpant-background-color: rgba(0,0,0,$alpha);
$box-shadow: 0 0 20px 2px $transpant-background-color;

$navbar-default-bg: #000;

$segoe-ui: "Segoe UI", "Segoe WP", "Open Sans", "Helvetica Neue", sans-serif;
$segoe-light: "Segoe UI Light", "Open Sans", "Helvetica Neue", "Segoe UI", "Segoe WP", sans-serif;

/* URL locations */
$images-folder: "/Images";

/* Colors */
$gray: #626262;
$gray-1: #575757;
$gray-2: #53565a;

$dark-gray-1: #75787b;

$light-gray: #c2c4c5;
$light-gray-1: #d0d0ce;
$light-gray-2: #e6e6e6;
$light-gray-3: #e2e2e2;
$light-gray-4: #6f7275;
$lighter-gray: #ccc;
$lighter-gray-1: #97999b;
$lighter-gray-2: #f6f6f6;

$light-green: #9bd548;
$light-green-2: #86bc25;
$light-green-3: #00abab;

$light-blue: #0f9fdd;
$light-blue-1: #00a3e0;
$lighter-blue: #105698;

$black: #000;
$black-1: #010101;
$white: #fff;
$white1: #f8f8fa;

/* Global Box Shadow */
$global-box-shadow: 0 0 12px 0px fade($black, 14%);

/*Spacing*/
$default-gutter-spacing: 30px;
$default-gutter-spacing-form: 20px;

/* Global Class */
.hidden {
    display: none !important;
}

.row {
    display: table;
    margin-left: -15px;
    margin-right: -15px;
    width: 100%;
}

.half-col {
    float: left;
    width: 50%;
    padding-left: $default-gutter-spacing/2;
    padding-right: $default-gutter-spacing/2;
}

.strong, strong {
    font-weight: bold;
}

.semi-strong {
    font-weight: 600;
}

.margin-top-1 {
    margin-top: 5px;
}

.margin-top-2 {
    margin-top: 10px;
}

.margin-top-3 {
    margin-top: 15px;
}

.margin-top-4 {
    margin-top: 20px;
}

.margin-top-5 {
    margin-top: 25px;
}

.margin-top-6 {
    margin-top: 30px;
}


/*Font Size*/
$font-size-small-0: 8px;
$font-size-small-1: 11px;
$font-size-small-2: 12px;
$font-size-small-3: 13px;
$font-size-small-4: 10px;
$font-size-normal: 14px;
$font-size-medium-1: 16px;
$font-size-medium-2: 18px;
$font-size-medium-3: 20px;
$font-size-medium-4: 22px;
$font-size-big-1: 24px;
$font-size-big-2: 26px;
$font-size-big-3: 28px;
$font-size-big-4: 30px;
$font-size-big-5: 34px;
$font-size-big-6: 27px;


/*Font line height*/
$font-line-height-0: 1;
$font-line-height-small-0: 6px;
$font-line-height-small-1: 18px;
$font-line-height-small-2: 20px;
$font-line-height-small-3: 19px;
$font-line-height-small-4: 20px;
$font-line-height-small-5: 16px;
$font-line-height-normal: 22px;
$font-line-height-medium-0: 24px;
$font-line-height-medium-1: 26px;
$font-line-height-medium-2: 28px;
$font-line-height-medium-4: 32px;
$font-line-height-medium-3: 30px;
$font-line-height-medium-5: 28px;
$font-line-height-medium-6: 33px;
$font-line-height-medium-7: 34px;
$font-line-height-big-1: 36px;
$font-line-height-big-2: 52px;
$font-line-height-big-3: 46px;
$font-line-height-big-4: 40px;
$font-line-height-big-5: 42px;
$font-line-height-big-6: 48px;
$font-line-height-big-7: 51px;
$font-line-height-big-8: 56px;
$font-line-height-big-9: 66px;
$font-line-height-big-10: 44px;

.btn-link {
    color: $light-blue-1;
    text-decoration: none;
}
